// pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import SessionTimeout from './SessionTimeout';
import { Box, Button, Input, Stack, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { addP2pCamera, getP2pCameras } from '../actions/cameraActions';
import { Link } from 'react-router-dom';
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { toast, ToastContainer } from 'react-toastify';
import { FaSort } from "react-icons/fa";

const CameraList = () => {

    const [cameraa, setCameraa] = useState([]);
    const [deviceId, setDeviceId] = useState('');
    const getConfig = async () => {
        try {
            const response = await getP2pCameras();
            setCameraa(response.data);
        } catch (error) {
            console.error('Error:', error);
            // navigate('/404');
        }
    };

    useEffect(() => {
        getConfig();
    }, []);

    const handleAddCamera = async () => {
        try {
            const response = await addP2pCamera(deviceId);
            toast.success(response.data.message);
            getConfig();
            setDeviceId('');
            // setFrameRate(response.data.frameRate);
            // navigate('/404');
        } catch (error) {
            setDeviceId('');
            toast.error(error.response.data.message);
            console.error('Error:', error);
            // navigate('/404');
        }
    }

    const [isAscending, setIsAscending] = useState(true); // State to track sorting order

    const handleStatusSort = () => {
        const sortedCameras = [...cameraa].sort((a, b) => {
            if (isAscending) {
                return a.status > b.status ? 1 : -1; // Sort ascending (offline first)
            } else {
                return a.status < b.status ? 1 : -1; // Sort descending (online first)
            }
        });

        setCameraa(sortedCameras); // Update the state with the sorted camera array
        setIsAscending(!isAscending); // Toggle the sorting order for next click
    };

    return (
        <>
            <Box p={8} display={'flex'} flexDirection={'column'}>
                <ToastContainer />
                <SessionTimeout timeoutDuration={1800000} />
                {/* <Box >
                    <Input value={deviceId} onChange={(e) => setDeviceId(e.target.value)} />
                    <Button onClick={handleAddCamera} colorScheme='green' variant='outline' size='sm' mt={2} mb={2} width={20} display={'flex'}>
                        ADD
                    </Button>
                </Box> */}
                <Stack
                    direction={['column', 'row']} // Column on mobile, row on larger screens
                    justify="flex-end"
                    align="center"
                    spacing={4} // Space between Input and Button
                    mb={1}
                >
                    <Input
                        value={deviceId}
                        onChange={(e) => setDeviceId(e.target.value)}
                        placeholder="Enter Device ID"
                        size="md"
                        maxWidth="200px"
                        focusBorderColor="green.400" // Custom border color on focus
                        _focus={{
                            boxShadow: 'none', // Remove default shadow
                            borderColor: 'green.400', // Custom border color on focus
                        }}
                    />
                    <Button
                        onClick={handleAddCamera}
                        colorScheme='green'
                        variant='outline'
                        size='md' // Changed to 'md' for better alignment
                    >
                        ADD
                    </Button>
                </Stack>
                <TableContainer w={'full'}>
                    <Table variant='striped' colorScheme='gray' borderWidth="1px" borderColor="gray.200">
                        <TableCaption>Your Installed Camera List</TableCaption>
                        <Thead>
                            <Tr>
                                <Th borderRight="1px" borderColor="gray.300" bgColor='#1C2536' color='white'>Sr.No.</Th>
                                <Th borderRight="1px" borderColor="gray.300" bgColor='#1C2536' color='white'>Device ID</Th>
                                <Th borderRight="1px" borderColor="gray.300" bgColor='#1C2536' color='white'>MQTT Url</Th>

                                {/* Status column with a button in the header */}
                                <Th borderRight="1px" borderColor="gray.300" bgColor='#1C2536' color='white' display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    Status
                                    <Button
                                        onClick={handleStatusSort} // Add your sorting function here
                                        // colorScheme="teal"
                                        variant="ghost"
                                        color="white"
                                        size="xs"
                                        ml={2} // Adds some margin to the left
                                    >
                                        <FaSort/>
                                    </Button>
                                </Th>

                                <Th borderRight="1px" borderColor="gray.300" bgColor='#1C2536' color='white'>Web Url</Th>
                                <Th borderRight="1px" borderColor="gray.300" bgColor='#1C2536' color='white'>Telnet Url</Th>
                                <Th borderRight="1px" borderColor="gray.300" bgColor='#1C2536' color='white'>Media Url</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {cameraa
                                .map((camera, index) => (
                                    <Tr key={index}>
                                        <Td borderRight="1px" borderColor="gray.300">{index + 1}</Td>
                                        <Td borderRight="1px" borderColor="gray.300">{camera.deviceId}</Td>
                                        <Td borderRight="1px" borderColor="gray.300">{camera.mqttUrl}</Td>

                                        {/* Status column with status icon */}
                                        <Td borderRight="1px" borderColor="gray.300">
                                            {camera.status === 'online' ? '🟢' : '🔴'}
                                        </Td>

                                        <Td borderRight="1px" borderColor="gray.300">
                                            <Link to={camera.weburl} target="_blank" rel="noopener noreferrer">
                                                <FaArrowUpRightFromSquare />
                                            </Link>
                                        </Td>
                                        <Td borderRight="1px" borderColor="gray.300">
                                            <Button colorScheme='blue' variant='outline' size='sm'>
                                                Enable
                                            </Button>
                                        </Td>
                                        <Td borderRight="1px" borderColor="gray.300"></Td>
                                    </Tr>
                                ))
                            }
                        </Tbody>

                    </Table>
                </TableContainer>

            </Box >
        </>
    );
}

export default CameraList;
