// pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Box, Grid, theme } from '@chakra-ui/react';
import CustomCard from '../components/CustomCard';
import { BsCurrencyDollar } from 'react-icons/bs';
import PieChartComponent from '../components/PieChartComponent';
// import mqtt from 'mqtt';
import { fetchLatestMessage } from '../actions/cameraActions';
import SessionTimeout from './SessionTimeout';

const Dashboard = () => {
  const [serverInfo, setServerInfo] = useState({
    totalRam: 'N/A',
    usedRam: 'N/A',
    ramUsagePercent: 0,
    freeRamPercent: 0,
    totalStorage: 'N/A',
    usedStorage: 'N/A',
    storageUsagePercent: 0,
    freeStoragePercent: 0,
    bandwidth: 'N/A',
    bandwidthUsagePercent: 0,
    cpuLoadPercent: 0,
    connectedClients: 0,
    os: {
      platform: 'N/A',
      distro: 'N/A',
      arch: 'N/A',
      uptime: 'N/A'
    }
  });

  const [mediaserverInfo, setMediaServerInfo] = useState({
    totalRam: 'N/A',
    usedRam: 'N/A',
    ramUsagePercent: 0,
    freeRamPercent: 0,
    totalStorage: 'N/A',
    usedStorage: 'N/A',
    storageUsagePercent: 0,
    freeStoragePercent: 0,
    bandwidth: 'N/A',
    bandwidthUsagePercent: 0,
    cpuLoadPercent: 0,
    connectedClients: 0,
    os: {
      platform: 'N/A',
      distro: 'N/A',
      arch: 'N/A',
      uptime: 'N/A'
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchLatestMessage();
        setServerInfo({
          totalRam: response.data.totalRam,
          usedRam: response.data.usedRam,
          ramUsagePercent: parseFloat(response.data.ramUsagePercent),
          freeRamPercent: parseFloat(response.data.freeRamPercent),
          totalStorage: response.data.totalStorage,
          usedStorage: response.data.usedStorage,
          storageUsagePercent: parseFloat(response.data.storageUsagePercent),
          freeStoragePercent: parseFloat(response.data.freeStoragePercent),
          bandwidth: response.data.bandwidth,
          bandwidthUsagePercent: parseFloat(response.data.bandwidthUsagePercent),
          cpuLoadPercent: parseFloat(response.data.cpuLoadPercent),
          connectedClients: response.data.connectedClients,
          os: response.data.os,
        });
      } catch (error) {
        console.error("Error fetching server info:", error);
      }
    };

    fetchData(); // Fetch data immediately on mount

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000); // Call fetchData every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  const pieChartCpu = {
    chart: {
      type: 'pie',
      height: '100%',
    },
    colors: [theme.colors.purple[500], theme.colors.blue[500]],
    labels: ['free', 'used'],
    title: {
      text: 'Provisioning CPU Usage',
      align: 'left',
    },
    legend: {
      position: 'top',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false
      }
    },
    tooltip: {
      fillSeriesColor: false
    },
    states: {
      active: {
        filter: {
          type: 'none'
        }
      },
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
  };

  const pieChartSeriesCpu = [100 - serverInfo.cpuLoadPercent, serverInfo.cpuLoadPercent];

  const pieChartRam = {
    chart: {
      type: 'pie',
      height: '100%',
    },
    colors: [theme.colors.purple[500], theme.colors.blue[500]],
    labels: ['free', 'used'],
    title: {
      text: 'Provisioning Ram Usage',
      align: 'left',
    },
    legend: {
      position: 'top',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false
      }
    },
    tooltip: {
      fillSeriesColor: false
    },
    states: {
      active: {
        filter: {
          type: 'none'
        }
      },
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
  };

  const pieChartSeriesRam = [serverInfo.freeRamPercent, serverInfo.ramUsagePercent];

  return (
    <Box p={4}>
      <SessionTimeout timeoutDuration={1800000} />
      <Box padding='0% 2% 0.1%'>
        <p>Provisioning Server</p>
      </Box>
      <Grid
        width='100%'
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(4, 1fr)",
        }}
        gap={6}
        padding='1% 2% 0.1%'
      >
        <CustomCard
          title="Ram Usage"
          value={`${serverInfo.usedRam}/${serverInfo.totalRam}`}
          // sanand="55539"
          color="blue.500"
          bcolor="white"
          IconComponent={BsCurrencyDollar}
        />
        <CustomCard
          title="Storage Usage"
          value={`${serverInfo.usedStorage}/${serverInfo.totalStorage}`}
          color="green.500"
          bcolor="white"
          IconComponent={BsCurrencyDollar}
        />
        <CustomCard
          title="Network Bedwidth"
          value={`${serverInfo.bandwidth}`}
          color="orange.500"
          bcolor="white"
          IconComponent={BsCurrencyDollar}
        />
        <CustomCard
          title="Connected Devices"
          value={`${serverInfo.connectedClients}`}
          color="purple.500"
          bcolor="white"
          IconComponent={BsCurrencyDollar}
        />
      </Grid>

      {/* media server */}
      <Box padding='1.5% 2% 0.1%'>
        <p>Media Server</p>
      </Box>
      <Grid
        width='100%'
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(4, 1fr)",
        }}
        gap={6}
        padding='1% 2% 0.1%'
      >
        <CustomCard
          title="Ram Usage"
          value={`${mediaserverInfo.usedRam}/${mediaserverInfo.totalRam}`}
          // sanand="55539"
          color="blue.500"
          bcolor="white"
          IconComponent={BsCurrencyDollar}
        />
        <CustomCard
          title="Storage Usage"
          value={`${mediaserverInfo.usedStorage}/${mediaserverInfo.totalStorage}`}
          color="green.500"
          bcolor="white"
          IconComponent={BsCurrencyDollar}
        />
        <CustomCard
          title="Network Bedwidth"
          value={`${mediaserverInfo.bandwidth}`}
          color="orange.500"
          bcolor="white"
          IconComponent={BsCurrencyDollar}
        />
        <CustomCard
          title="CPU Usage"
          value={`${mediaserverInfo.cpuLoadPercent} %`}
          color="purple.500"
          bcolor="white"
          IconComponent={BsCurrencyDollar}
        />
      </Grid>
      <Grid
        width="100%"
        templateColumns={{
          base: "repeat(1, 1fr)",
          xl: "2fr 2fr",
          lg: "2fr 2fr",
          md: "1fr 1fr",
          sm: "1fr 1fr",
        }}
        gap={6}
        padding="2% 2%"
        height="500px"
      >
        <Box height="100%" display="flex" flexDirection="column">
          <Box flex="1">
            <PieChartComponent
              options={pieChartCpu}
              series={pieChartSeriesCpu}
            />
          </Box>
        </Box>
        <Box height="100%" display="flex" flexDirection="column">
          <Box flex="1">
            <PieChartComponent
              options={pieChartRam}
              series={pieChartSeriesRam}
            />
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}

export default Dashboard;
